
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IProduct} from "@/apis/product/types";
import {Message} from "element-ui";
import config from "@/config";
import {ICategory} from "@/apis/category/types";
import {queryProductSpecsListApi} from "@/apis/productSpecs";
import {getProductBrandListApi} from "@/apis/product";
import {ISpecs} from "@/apis/productSpecs/types";

@Component({})
export default class ProductBasic extends Vue {
  config = config;
  // 新增修改表单
  @Prop(Object) modelForm!: IProduct;
  // 新增修改表单rules
  @Prop(Object) rules!: any;

  //分类下拉数据
  sortTreeList: ICategory[] = [];
  //商品品牌下拉数据
  productBrandList: any[] = [];

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * IUpload
   * 文件提交，成功或失败
   * @param res
   */
  onFileUpload(res: any) {
    if (res) {
      console.log(res, 'onFileUpload')
    }

  }

  /**
   * 上传视频前检验
   * @param file
   */
  beforeVideoUpload(file: File) {
    let fileSize = file.size / 1024 / 1024 < 50;   //控制大小  修改50的值即可
    if (
      [
        "video/mp4",
        "video/ogg",
        "video/flv",
        "video/avi",
        "video/wmv",
        "video/rmvb",
        "video/mov",
      ].indexOf(file.type) == -1     //控制格式
    ) {
      Message.error("请上传正确的视频格式");
      return false;
    }
    if (!fileSize) {
      Message.error("视频大小不能超过50MB");
      return false;
    }
  }

  /**
   * 下一步，上一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next', this.modelForm,tab)
  }

  handleExceed(files:any, fileList:any) {
    this.$message.error(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
  }

  /**
   * 商品品牌下拉
   */
  async getProductBrandList() {
    await getProductBrandListApi().then(e => {
      this.productBrandList = e;
    })
  }
  //分类 点击 加载分类数据
  focusSortTree(){
    this.$emit('getSortTree', this.modelForm)
  }
  //品牌 点击 加载分类数据
  focusProductBrand(){
    this.productBrandList =[];
    this.getProductBrandList();
  }
  created() {
    this.getProductBrandList();
  }
}
